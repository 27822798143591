// 创建 encrypt.js 文件

import CryptoJS from 'crypto-js';

// ------------AES 加密-------------

function getAesString(data, key, iv) {//加密
  let keys = CryptoJS.enc.Utf8.parse(key)
  let vis = CryptoJS.enc.Utf8.parse(iv)
  let encrypt = CryptoJS.AES.encrypt(data, keys, {
    iv: vis, //iv偏移量 CBC需加偏移量
    mode: CryptoJS.mode.CBC, //CBC模式
    // mode: CryptoJS.mode.ECB, //ECB模式
    padding: CryptoJS.pad.Pkcs7 //padding处理
  });
  // debugger
  return encrypt.toString(); //加密完成后，转换成字符串
}

// ------------AES 解密-------------
function getDAesString(encrypted, key, iv) { // 解密
  var key  = CryptoJS.enc.Utf8.parse(key);
  var iv   = CryptoJS.enc.Utf8.parse(iv);
  var decrypted =CryptoJS.AES.decrypt(encrypted,key,{
    iv:iv,
    mode:CryptoJS.mode.CBC,
    padding:CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// AES 对称秘钥加密
const aes = {
  en: (data, key) => getAesString(data, key.key, key.iv),
  de: (data, key) => getDAesString(data, key.key, key.iv),
    key:'C62EDC26865CAFFD857A414CF0037B6B',
  iv:'C62EDC26865CAFFD'
};

export { aes };
