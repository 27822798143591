const SM4 = require('gm-crypt').sm4
const pwdKey = 'C62EDC26865CAFFD' // 密钥 前后端一致即可,后端提供
const iv = 'C62EDC26865CAFFD'
let sm4Config = {
  key: pwdKey, // key值要与后端的一致，后端解密是根据这个key
  mode: 'cbc', // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，cbc的话下面还要加一个iv的参数，ecb不用
  iv: iv, // iv是cbc模式的第二个参数，也需要跟后端配置的一致 iv是initialization vector的意思，就是加密的初始化矢量，初始化加密函数的变量，也叫初始向量
  cipherType: 'base64',
}

/*
 * 加密工具函数
 * @param {String} text 待加密文本
 * @param key string 加密key(16位)
 * @param iv string 偏移向量(16位)
 */
export function SM4Encrypt(text, key = pwdKey, iv = pwdKey) {
  sm4Config.key = key
  sm4Config.iv = iv
  const sm4Util = new SM4(sm4Config) // new一个sm4函数，将sm4Config作为参数传递进去
  return sm4Util.encrypt(text, key)
}

/*
 * 解密工具函数
 * @param {String} text 待解密密文
 * @param key string 加密key(16位)
 * @param iv string 偏移向量(16位)
 */
  export function SM4Decrypt(text, key = pwdKey, iv = pwdKey) {
  sm4Config.key = key
  sm4Config.iv = iv
  const sm4Util = new SM4(sm4Config) // new一个sm4函数，将sm4Config作为参数传递进去
  return sm4Util.decrypt(text, key)
}
